// CountrySelector.js
import { h } from "preact";
import { useState } from "preact/hooks";
import Select from "antd/es/select";
import { useTranslation } from "react-i18next";
import { allCountryCodes } from "./utils";
const { Option } = Select;

// TODO remake so it looks like othmer inputs
const CountrySelector = ({ placeholder, setDoctypeCountry, onChange, countrySelectorOptions }) => {
  const { t } = useTranslation();

  const [isPlaceholderVisible, setIsPlaceholderVisible] = useState(true);

  const handleFocus = () => {
    setIsPlaceholderVisible(false);
  };

  const handleOnChange = (value) => {
    if (onChange) {
      onChange(value);
    }
    if (setDoctypeCountry) {
      setDoctypeCountry(value);
    }
  }

  const mappedCountries = () => {
    const countryNames = [];

    allCountryCodes.forEach((countryCode) => {
      countryNames.push([countryCode, t(countryCode)]);
    });

    countryNames.sort();

    return countryNames;
  };

  const filterCountryOption = (input, option) => {
    const countryName = option.props.children;

    return countryName.toLowerCase().startsWith(input.toLowerCase());
  };

  return (
    countrySelectorOptions.country_selector_visible &&
    <Select
      placeholder={isPlaceholderVisible ? !countrySelectorOptions.country_selector_enabled ? t(countrySelectorOptions.country_code_default) : placeholder : ""}
      onFocus={handleFocus}
      onChange={handleOnChange}
      showSearch
      filterOption={filterCountryOption}
      style={{ width: "80%", marginLeft: "auto", marginRight: "auto" }}
      className="country-selector"
      disabled={!countrySelectorOptions.country_selector_enabled}
    >
      {countrySelectorOptions.country_selector_enabled ? mappedCountries().map((countryName) => (
        <Option key={countryName[0]} value={countryName[0]}>
          {countryName[1]}
        </Option>
      )) : 
        <Option key={countrySelectorOptions.country_code_default} selected value={countrySelectorOptions.country_code_default}>
          {t(countrySelectorOptions.country_code_default)}
        </Option>
      }
    </Select>
  );
};

export default CountrySelector;
