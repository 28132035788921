import { h } from "preact";
import {useTranslation} from "react-i18next";


export default function IncorrectCode() {

    const { t } = useTranslation();

    return (
        <div className="vg-content doc-rec-egov-code-error-subpage-content">
            <h3 className="vg-page-title doc-rec-egov-code-error-subpage-title">
                {t("IncorrectCodeTitle")}
            </h3>

            <div className="vg-text doc-rec-egov-code-error-subpage-text">
                {t("IncorrectCodeText")}
            </div>
        </div>
    );
}
