import axios from 'axios';
import axiosRetry from "axios-retry";


export function requestPreset(flowId) {
  const origin = window.location.origin;
  const url = `${origin}/flow/${flowId}/frontend/config`;

  const client = axios.create();

  axiosRetry(client, {retries: 30, retryDelay: 1000});

  axiosRetry(client, {retries: 10, retryDelay: 3000,
    retryCondition: (error) => {return true}}
  );

  return client.get(url)
}


export async function executeBackground(flowId, service, preset) {

  const targetUrl = `${window.location.origin}/flow/${flowId}/exec/${service}`;

  const headers = {
    'X-Verigram-Access-Token': preset["access_token"],
    'X-Verigram-Api-Version': preset["api_version"],
    'X-Verigram-Person-ID': preset["person_id"],
  };
  
  const client = axios.create();

  axiosRetry(client, {retries: 10, retryDelay: 3000,
    retryCondition: (error) => {return true}}
  );

  return await axios.post(targetUrl, null, {headers});
}


export function makeConsentRequest(agreements, credentials, flowId) {
  const origin = window.location.origin;
  const url = `${origin}/flow/${flowId}/exec/consent`;

  const config = {
    headers: {
      'X-Verigram-Api-Version': credentials.api_version,
      'X-Verigram-Access-Token': credentials.access_token,
      'X-Verigram-Person-Id': credentials.person_id,
    },
  };

  const client = axios.create();

  const data = {
    "agreements": agreements,
  }

  axiosRetry(client, {retries: 1,
    retryCondition: (error) => {return true}}
  );

  return client.post(url, data, config)
}


export function makeFlowFormRequest(data, credentials, flowId) {
  const origin = window.location.origin;
  const url = `${origin}/flow/${flowId}/exec/flow-form`;

  const config = {
    headers: {
      'X-Verigram-Api-Version': credentials.api_version,
      'X-Verigram-Access-Token': credentials.access_token,
      'X-Verigram-Person-Id': credentials.person_id,
    },
  };

  const client = axios.create();

  axiosRetry(client, {retries: 1,
    retryCondition: (error) => {return true}}
  );

  return client.post(url, data, config)
}


export function fetchCss(sourceUrl) {
  const client = axios.create();

  axiosRetry(client, {retries: 10, retryDelay: 1000,
    retryCondition: (error) => {return true}}
  );

  return client.get(sourceUrl)
    .then(response => {
      // Create a style element
      const styleElement = document.createElement('style');
      styleElement.type = 'text/css';
      styleElement.appendChild(document.createTextNode(response.data));

      // Append the style element to the document head
      document.head.appendChild(styleElement);
    })
    .catch(error => console.error('Error fetching CSS:', error));
}


export function applyCss(cssText) {
  // Create a style element
  const styleElement = document.createElement('style');
  styleElement.type = 'text/css';
  styleElement.appendChild(document.createTextNode(cssText));

  // Append the style element to the document head
  document.head.appendChild(styleElement);
}
