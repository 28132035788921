import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import ChainedBackend from "i18next-chained-backend";
import HttpBackend from "i18next-http-backend";
import resourcesToBackend from "i18next-resources-to-backend";


export async function initI18next(preset) {
  const locale = preset.locale;

  const customTranslation = {
    custom: {
      translation: preset?.template?.ui?.translation,
    },
  }

  const instance = await i18next
    .use(initReactI18next)
    .use(ChainedBackend)
    .init({
      debug: false,

      fallbackLng: {
        "default": ["custom", locale, "en"],
      },

      backend: {
        backends: [
          HttpBackend,
          resourcesToBackend(customTranslation),
        ],

        backendOptions: [
          {
            loadPath: (lngs, namespaces) => {
              let targetPath;
              if (lngs[0] === "custom") {
                return null;
              } else {
                targetPath = `/hf2/locales/${lngs[0]}/translation.json?version=${new Date().getTime()}`
              }
              return targetPath;
            }
          }
        ]
      },

      cache: {
        enabled: false,
      },
    });

  return instance;
}
