import { h } from "preact";
import { useEffect, useState } from "preact/hooks";
import { executeBackground } from "../../hooks/request";
import { countTotalSteps, getWhichStep } from "../../hooks/utils";
import LoadingWheel from "../../components/LoadWheel";
import StepIndicator from "../../components/StepIndicator";
import { useTranslation } from "react-i18next";

export default function VeriphonePage(props) {
  const { t } = useTranslation();

  useEffect(async () => {
    const preset = props.preset;

    await executeBackground(props.flowId, "veriphone", preset);
  }, []);

  const logoUrl = props?.preset?.template?.ui?.logo_url;

  const currentStep = getWhichStep("veriphone", props.preset);
  const totalSteps = countTotalSteps(props.preset);

  return (
    <div className="veriphone-page-root-div">
      {logoUrl && (
        <img
          className="vg-consumer-logo veriphone-page-consumer-logo"
          src={logoUrl}
          alt="Logo Image"
        />
      )}

      <h3 className="vg-page-title veriphone-page-title">{t("VeriphoneTitle")}</h3>

      <div className="vg-loading-wheel veriphone-page-loading-wheel">
        <LoadingWheel />

        <div className="veriphone-page-loading-wheel-text">
          {t("VeriphoneLoadingWheelText")}
        </div>
      </div>

      <div className="veriphone-page-step-indicator">
        <StepIndicator currentStep={currentStep} totalSteps={totalSteps} />
      </div>
    </div>
  );
}
