import {h} from "preact";
import {useContext, useEffect, useState} from "preact/hooks";
import {
    getVeridocConfigParams,
    getVeridocTargetUrl,
    getVeridocOptions,
    getCountrySelectorOptions,
    getShowSelectorPageFlag
} from "./VeridocUtils";
import Button from "antd/es/button";
import DocumentProcessChoice from "./DocumentProcessChoice";
import DocumentTypeChoice from "./DocumentTypeChoice";
import BackButton from "../../components/BackButton";
import CodeAppChoice from "./EgovCode/CodeAppChoice";
import HalykAppGuide from "./EgovCode/HalykAppGuide";
import KaspiAppGuide from "./EgovCode/KaspiAppGuide";
import EgovAppGuide from "./EgovCode/EgovAppGuide";
import CodeInput from "./EgovCode/CodeInput";
import Scanner from "./Scanner";
import {CodeAppChoiceEnum, DocProcessEnum, DocTypeEnum, VeridocSubpage} from "./enums";
import {makeEgovCodeRequest} from "./EgovCode/repo";
import IncorrectCode from "./EgovCode/IncorrectCode";
import {useTranslation} from "react-i18next";

export default function VeridocPage(props) {
    const {t} = useTranslation();

    const veridocOption = getVeridocOptions(props?.preset?.template?.pipeline);
    const countrySelectorOptions = getCountrySelectorOptions(props?.preset?.template?.pipeline);
    const showSelectorPage = getShowSelectorPageFlag(props?.preset?.template?.pipeline);

    let defaultPage = VeridocSubpage.PROCESS_CHOICE;
    if (veridocOption.length === 1) {
        if (veridocOption[0] === "scan") {
            defaultPage = showSelectorPage ? VeridocSubpage.DOCTYPE_CHOICE : VeridocSubpage.SCAN;
        } else {
            defaultPage = VeridocSubpage.EGOV_CODE_APP_CHOICE;
        }
    }
    // veridocOption.length === 1
    //   ? veridocOption[0] === "scan"
    //     ? VeridocSubpage.DOCTYPE_CHOICE
    //     : VeridocSubpage.EGOV_CODE_APP_CHOICE
    //   : VeridocSubpage.PROCESS_CHOICE;


    const [subpage, setSubpage] = useState(defaultPage);

    const [processChoice, setProcessChoice] = useState(null);
    const [doctypeChoice, setDoctypeChoice] = useState(null);
    const [doctypeCountry, setDoctypeCountry] = useState(null);

    const [egovCode, setEgovCode] = useState(null);
    const [sendEgovCodeBtnActive, setSendEgovCodeBtnActive] = useState(false);

    const countrySelectionAvailable = countrySelectorOptions.country_selector_enabled && countrySelectorOptions.country_selector_visible
    let retried = 0;

    const onSuccessCallback = (data) => {
        console.log("VeridocPage onSuccessCallback is called: ", data);
    };

    const onErrorCallback = (data) => {
        console.log("VeridocPage onErrorCallback is called: ", data);
        retried++;
        // TODO max retry mechanism
    };

    const onUpdateCallback = (data) => void 0;

    const logoUrl = props?.preset?.template?.ui?.logo_url;

    const handleProcessChoice = (value) => {
        setProcessChoice(value);
    };

    const handleDoctypeChoice = (value) => {
        switch (value) {
            case DocTypeEnum.PASSPORT:
                setDoctypeChoice(42);
                break;
            case DocTypeEnum.ID:
                setDoctypeChoice(1);
                break;
            case DocTypeEnum.RESIDENCE:
                setDoctypeChoice(1);
                break;
        }
    }

    const onProcessChoiceNextButtonClick = async (event) => {
        const flag = getShowSelectorPageFlag(props?.preset?.template?.pipeline);
        console.log("flag: ", flag);
        console.log("process choice: ", processChoice);
        switch (processChoice) {
            case DocProcessEnum.SCAN:
                if (!flag) {
                    setSubpage(VeridocSubpage.SCAN);
                } else {
                    setSubpage(VeridocSubpage.DOCTYPE_CHOICE);
                }
                break;
            case DocProcessEnum.EGOV_CODE:
                setSubpage(VeridocSubpage.EGOV_CODE_APP_CHOICE);
                break;
        }
        setProcessChoice(null);
    };

    const onDoctypeChoiceNextButtonClick = async (event) => {
        setSubpage(VeridocSubpage.SCAN)
    }

    const handleCodeAppChoice = (appChoice) => {
        switch (appChoice) {
            case CodeAppChoiceEnum.HALYK:
                setSubpage(VeridocSubpage.EGOV_CODE_HALYK_GUIDE);
                break;
            case CodeAppChoiceEnum.KASPI:
                setSubpage(VeridocSubpage.EGOV_CODE_KASPI_GUIDE);
                break;
            case CodeAppChoiceEnum.EGOV:
                setSubpage(VeridocSubpage.EGOV_CODE_EGOV_GUIDE);
                break;
        }
    };

    const onCodeAppChoiceNextButtonClick = async (event) => {
        setSubpage(VeridocSubpage.EGOV_CODE_INPUT);
    };

    const onHalykAppGuideNextButtonClick = async (event) => {
        setSubpage(VeridocSubpage.EGOV_CODE_INPUT);

        window.open(
            "https://homebank.kz/partners/govtech?url=https://gt.homebank.kz/services",
            "_blank"
        );
    };

    const onKaspiAppGuideNextButtonClick = async (event) => {
        setSubpage(VeridocSubpage.EGOV_CODE_INPUT);

        window.open("https://kaspi.kz/publicservices", "_blank");
    };

    const onEgovAppGuideNextButtonClick = async (event) => {
        setSubpage(VeridocSubpage.EGOV_CODE_INPUT);

        window.open("https://egovmobile.page.link/", "_blank");
    };

    const onEgovCodeInput = async (code) => {
        setEgovCode(code);
        setSendEgovCodeBtnActive(true);
    };

    const onEgovCodeSend = async () => {
        makeEgovCodeRequest(egovCode, props.preset, props.flowId)
            .then((response) => {
                console.log("egov code page response: ", response);
            })
            .catch((error) => {
                setSubpage(VeridocSubpage.EGOV_CODE_ERROR);
            });
        setSendEgovCodeBtnActive(false);
    };

    const onEgovRetry = async (event) => {
        setSubpage(VeridocSubpage.EGOV_CODE_INPUT);
    };

    const onEgovTryAnotherProcess = async (event) => {
        setSubpage(VeridocSubpage.PROCESS_CHOICE);
    };

    const onBackButtonClick = async (event) => {
        switch (subpage) {
            case VeridocSubpage.DOCTYPE_CHOICE:
                setSubpage(VeridocSubpage.PROCESS_CHOICE);
                break;
            case VeridocSubpage.SCAN:
                if (showSelectorPage) {
                    setSubpage(VeridocSubpage.DOCTYPE_CHOICE);
                } else {
                    setSubpage(VeridocSubpage.PROCESS_CHOICE);
                }
                setDoctypeChoice(null);
                break;
            case VeridocSubpage.EGOV_CODE_APP_CHOICE:
                setSubpage(VeridocSubpage.PROCESS_CHOICE);
                break;
            case VeridocSubpage.EGOV_CODE_HALYK_GUIDE:
                setSubpage(VeridocSubpage.EGOV_CODE_APP_CHOICE);
                break;
            case VeridocSubpage.EGOV_CODE_KASPI_GUIDE:
                setSubpage(VeridocSubpage.EGOV_CODE_APP_CHOICE);
                break;
            case VeridocSubpage.EGOV_CODE_EGOV_GUIDE:
                setSubpage(VeridocSubpage.EGOV_CODE_APP_CHOICE);
                break;
            case VeridocSubpage.EGOV_CODE_INPUT:
                setSubpage(VeridocSubpage.EGOV_CODE_APP_CHOICE);
                break;
            case VeridocSubpage.EGOV_CODE_ERROR:
                setSubpage(VeridocSubpage.EGOV_CODE_INPUT);
                break;
        }
    };

    let content;
    switch (subpage) {
        case VeridocSubpage.PROCESS_CHOICE:
            content = (
                <div className="doc-rec-process-choice-root-div">
                    {logoUrl && (
                        <img
                            className="vg-consumer-logo doc-rec-page-consumer-logo doc-rec-process-choice-subpage-consumer-logo"
                            src={logoUrl}
                            alt="Logo Image"
                        />
                    )}

                    <DocumentProcessChoice
                        logoUrl={logoUrl}
                        onRadioChange={handleProcessChoice}
                    />

                    <Button
                        className="vg-button vg-next-button doc-rec-process-choice-subpage-next-button"
                        disabled={!processChoice}
                        onClick={onProcessChoiceNextButtonClick}
                    >
                        {t("NextButton")}
                    </Button>
                </div>
            );
            break;

        case VeridocSubpage.DOCTYPE_CHOICE:
            content = (
                <div className="doc-rec-type-choice-root-div">
                    <BackButton onClick={onBackButtonClick}/>

                    {logoUrl && (
                        <img
                            className="vg-consumer-logo doc-rec-page-consumer-logo doc-rec-type-choice-subpage-consumer-logo"
                            src={logoUrl}
                            alt="Logo Image"
                        />
                    )}

                    <DocumentTypeChoice
                        logoUrl={logoUrl}
                        onRadioChange={handleDoctypeChoice}
                        setDoctypeCountry={setDoctypeCountry}
                        countrySelectorOptions={countrySelectorOptions}
                    />

                    <Button
                        className="vg-button vg-next-button doc-rec-process-choice-subpage-next-button"
                        disabled={countrySelectionAvailable ? (!doctypeChoice || !doctypeCountry) : !doctypeChoice}
                        onClick={onDoctypeChoiceNextButtonClick}
                    >
                        {t("NextButton")}
                    </Button>
                </div>
            );
            break;

        // case SubpageEnum.SCAN_GUIDE:
        //     content = <div>
        //         <BackButton
        //             className="antd-flow-back-button"
        //             onClick={onBackButtonClick}
        //         />
        //         {logoUrl && <img className="antd-consumer-logo" src={logoUrl} alt="Logo Image"/>}
        //
        //         <ScanGuide/>
        //
        //         <Button
        //             className="antd-flow-button"
        //             disabled={!processChoice}
        //             onClick={onProcessChoiceNextButtonClick}
        //             style={{'position': 'absolute', 'bottom': '50px'}}
        //         >
        // {t("NextButton")}
        //         </Button>
        //
        //         </div>;
        //         break;

        case VeridocSubpage.SCAN:
            content = (
                <div style={{height: "100%"}}>
                    {veridocOption.length !== 1 && (
                        <BackButton onClick={onBackButtonClick}/>
                    )}
                    {/*{logoUrl && <img className="antd-consumer-logo" src={logoUrl} alt="Logo Image"/>}*/}

                    <Scanner
                        preset={props.preset}
                        onSuccessCallback={onSuccessCallback}
                        onErrorCallback={onErrorCallback}
                        onUpdateCallback={onUpdateCallback}
                        flowId={props.flowId}
                        docType={doctypeChoice}
                    />
                </div>
            );
            break;

        case VeridocSubpage.EGOV_CODE_APP_CHOICE:
            content = (
                <div className="doc-rec-egov-code-app-choice-root-div">
                    {veridocOption.length !== 1 && (
                        <BackButton
                            className="vg-back-button doc-rec-egov-code-app-choice-subpage-back-button"
                            onClick={onBackButtonClick}
                        />
                    )}
                    {logoUrl && (
                        <img
                            className="vg-consumer-logo doc-rec-page-consumer-logo doc-rec-egov-code-app-choice-subpage-consumer-logo"
                            src={logoUrl}
                            alt="Logo Image"
                        />
                    )}

                    <CodeAppChoice onOptionChange={handleCodeAppChoice}/>

                    <Button
                        className="vg-button vg-next-button doc-rec-egov-code-app-choice-subpage-next-button"
                        onClick={onCodeAppChoiceNextButtonClick}
                    >
                        {t("NextButton")}
                    </Button>
                </div>
            );
            break;

        case VeridocSubpage.EGOV_CODE_HALYK_GUIDE:
            content = (
                <div className="doc-rec-egov-code-halyk-guide-root-div">
                    <BackButton
                        className="vg-back-button doc-rec-egov-code-halyk-guide-subpage-back-button"
                        onClick={onBackButtonClick}
                    />

                    {logoUrl && (
                        <img
                            className="vg-consumer-logo doc-rec-page-consumer-logo doc-rec-egov-code-halyk-guide-subpage-consumer-logo"
                            src={logoUrl}
                            alt="Logo Image"
                        />
                    )}

                    <HalykAppGuide/>

                    <Button
                        className="vg-button vg-next-button doc-rec-egov-code-halyk-guide-subpage-next-button"
                        onClick={onHalykAppGuideNextButtonClick}
                    >
                        {t("NextButton")}
                    </Button>
                </div>
            );
            break;

        case VeridocSubpage.EGOV_CODE_KASPI_GUIDE:
            content = (
                <div className="doc-rec-egov-code-kaspi-guide-root-div">
                    <BackButton
                        className="vg-back-button doc-rec-egov-code-kaspi-guide-subpage-back-button"
                        onClick={onBackButtonClick}
                    />

                    {logoUrl && (
                        <img
                            className="vg-consumer-logo doc-rec-page-consumer-logo doc-rec-egov-code-kaspi-guide-subpage-consumer-logo"
                            src={logoUrl}
                            alt="Logo Image"
                        />
                    )}

                    <KaspiAppGuide/>

                    <Button
                        className="vg-button vg-next-button doc-rec-egov-code-kaspi-guide-subpage-next-button"
                        onClick={onKaspiAppGuideNextButtonClick}
                    >
                        {t("NextButton")}
                    </Button>
                </div>
            );
            break;

        case VeridocSubpage.EGOV_CODE_EGOV_GUIDE:
            content = (
                <div className="doc-rec-egov-code-egov-guide-root-div">
                    <BackButton
                        className="vg-back-button doc-rec-egov-code-egov-guide-subpage-back-button"
                        onClick={onBackButtonClick}
                    />

                    {logoUrl && (
                        <img
                            className="vg-consumer-logo doc-rec-page-consumer-logo doc-rec-egov-code-egov-guide-subpage-consumer-logo"
                            src={logoUrl}
                            alt="Logo Image"
                        />
                    )}

                    <EgovAppGuide/>

                    <Button
                        className="vg-button vg-next-button doc-rec-egov-code-egov-guide-subpage-next-button"
                        onClick={onEgovAppGuideNextButtonClick}
                    >
                        {t("NextButton")}
                    </Button>
                </div>
            );
            break;

        case VeridocSubpage.EGOV_CODE_INPUT:
            content = (
                <div className="doc-rec-egov-code-input-root-div">
                    <BackButton
                        className="vg-back-button doc-rec-egov-code-input-subpage-back-button"
                        onClick={onBackButtonClick}
                    />

                    {logoUrl && (
                        <img
                            className="vg-consumer-logo doc-rec-page-consumer-logo doc-rec-egov-code-input-subpage-consumer-logo"
                            src={logoUrl}
                            alt="Logo Image"
                        />
                    )}

                    <CodeInput onCode={onEgovCodeInput}/>

                    <Button
                        className="vg-button vg-next-button doc-rec-egov-code-input-subpage-next-button"
                        onClick={onEgovCodeSend}
                        disabled={!sendEgovCodeBtnActive}
                    >
                        {t("NextButton")}
                    </Button>
                </div>
            );
            break;

        case VeridocSubpage.EGOV_CODE_ERROR:
            content = (
                <div className="doc-rec-egov-code-error-root-div">
                    <BackButton
                        className="vg-back-button doc-rec-egov-code-error-subpage-back-button"
                        onClick={onBackButtonClick}
                    />

                    {logoUrl && (
                        <img
                            className="vg-consumer-logo doc-rec-page-consumer-logo doc-rec-egov-code-error-subpage-consumer-logo"
                            src={logoUrl}
                            alt="Logo Image"
                        />
                    )}

                    <IncorrectCode/>

                    <div className="doc-rec-egov-code-error-subpage-button-group">
                        <Button
                            className="vg-button vg-next-button doc-rec-egov-code-error-subpage-next-button"
                            onClick={onEgovRetry}
                        >
                            {t("RetryButton")}
                        </Button>

                        {veridocOption.length !== 1 && (
                            <Button
                                className="vg-button vg-next-button doc-rec-egov-code-error-subpage-another-method-button"
                                onClick={onEgovTryAnotherProcess}
                            >
                                {t("AnotherMethodButton")}
                            </Button>
                        )}
                    </div>
                </div>
            );
            break;

        default:
            content = (
                <div>
                    <p>Error</p>
                </div>
            );
    }

    return <div className="doc-rec-page-root-div">{content}</div>;
}
