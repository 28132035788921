import { h } from "preact";
import { LeftCircleOutlined } from "@ant-design/icons";

export default function BackButton(props) {
  return (
    // <LeftCircleOutlined
    //   onClick={props.onClick}
    //   className="vg-back-button doc-rec-scan-subpage-back-button"
    // />
    <svg
      width="49"
      height="49"
      viewBox="0 0 49 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={props.onClick}
      className="vg-back-button doc-rec-scan-subpage-back-button"
    >
      <rect
        x="1.23926"
        y="1.2832"
        width="47"
        height="47"
        rx="23.5"
        stroke="#CCD1DB"
      />
      <path
        className="vg-back-button-path"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M28.0641 16.9589C28.5197 17.4145 28.5197 18.1532 28.0641 18.6088L21.889 24.7839L28.0641 30.9589C28.5197 31.4145 28.5197 32.1532 28.0641 32.6088C27.6084 33.0644 26.8697 33.0644 26.4141 32.6088L19.4141 25.6088C18.9585 25.1532 18.9585 24.4145 19.4141 23.9589L26.4141 16.9589C26.8697 16.5033 27.6084 16.5033 28.0641 16.9589Z"
        fill="#5E6A78"
      />
    </svg>
  );
}
