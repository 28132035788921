import { h } from "preact";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import LoadWheel from "../../../components/LoadWheel.jsx";

const QRState = ({logoUrl, loading, qrCodeImgSrc, keepOpen }) => {

  const { t } = useTranslation();

  return (
    <div className="device-filter-page-root-div">
      {logoUrl && (
        <img
          className="vg-consumer-logo device-filter-page-consumer-logo"
          src={logoUrl}
          alt="Logo Image"
        />
      )}

      <h3 className="vg-page-title device-filter-page-title">
        {t("DeviceFilterTitle")}
      </h3>

      {loading ? (
        <div className="vg-loading-wheel device-filter-page-loading-wheel">
          <LoadWheel />
        </div>
      ) : (
        <div className="device-filter-page-qrcode">
          <img id="qrCodeImg" alt="QR Code" src={qrCodeImgSrc} />
        </div>
      )}

      <div className="vg-text device-filter-page-text">
        {t("DeviceFilterText")}
      </div>
      
      {keepOpen && <h4 className="vg-page-title device-filter-page-title">
        {t("DeviceFilterSubtitle")}
      </h4>}

      <Button
        className="vg-button vg-next-button device-filter-page-next-button"
        disabled
      >
        {t("NextButton")}
      </Button>
    </div>
  );
};
export default QRState;
