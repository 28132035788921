import { h } from "preact";
import { useTranslation } from "react-i18next";

const ReturnState = ({logoUrl}) => {

  const { t } = useTranslation();

  return (
    <div className="device-filter-page-root-div">
      {logoUrl && (
        <img
          className="vg-consumer-logo device-filter-page-consumer-logo"
          src={logoUrl}
          alt="Logo Image"
        />
      )}

      <h3 className="vg-page-title device-filter-page-title">
        {t("DeviceFilterReturnTitle")}
      </h3>

      <div className="vg-text device-filter-page-text">
        {t("DeviceFilterReturnText")}
      </div>

    </div>
  );
};

export default ReturnState;
