import { h } from "preact";
import { DocTypeEnum } from "./enums";
import CountrySelector from "../../components/CountrySelector.jsx";
import { useTranslation } from "react-i18next";
import { Radio } from "antd";

export default function DocumentTypeChoice({
  onRadioChange,
  setDoctypeCountry,
  countrySelectorOptions
}) {
  const { t } = useTranslation();
  return (
    <div className="vg-content doc-rec-doctype-subpage-content">
      {countrySelectorOptions.country_selector_visible &&  
      <h3 className="vg-page-title doc-rec-doctype-subpage-title">
        {t("DocumentTypeChoiceTitle")}
      </h3>}

      <div className="doc-rec-doctype-subpage-country-selector">
        <CountrySelector
          placeholder={t("FlowFormInputCountry")}
          setDoctypeCountry={setDoctypeCountry}
          countrySelectorOptions={countrySelectorOptions}
        />
      </div>

      <h4 className="doc-rec-doctype-subpage-radio-header">
        {t("DocumentTypeChoiceHeader")}
      </h4>

      <div className="doc-rec-doctype-subpage-radio-group">
        <Radio.Group
          onChange={(e) => onRadioChange(e.target.value)}
          style={{ display: "flex", flexDirection: "column", rowGap: "10px" }}
        >
          <Radio
            value={DocTypeEnum.PASSPORT}
            id={DocTypeEnum.PASSPORT}
            name="docTypeChoice"
          >
            <span className="doc-rec-doctype-subpage-radio-button-text">
              {t("DocumentTypeChoicePassport")}
            </span>
          </Radio>

          <Radio
            value={DocTypeEnum.ID}
            id={DocTypeEnum.ID}
            name="docTypeChoice"
          >
            <span className="doc-rec-doctype-subpage-radio-button-text">
              {t("DocumentTypeChoiceID")}
            </span>
          </Radio>
          <Radio
            value={DocTypeEnum.RESIDENCE}
            id={DocTypeEnum.RESIDENCE}
            name="docTypeChoice"
          >
            <span className="doc-rec-doctype-subpage-radio-button-text">
              {t("DocumentTypeChoiceResidence")}
            </span>
          </Radio>
        </Radio.Group>
      </div>
    </div>
  );
}
