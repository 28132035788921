import axios from 'axios';
import axiosRetry from "axios-retry";


export function makeEgovCodeRequest(code, credentials, flowId) {
  const origin = window.location.origin;
  const url = `${origin}/egov/doc/shared/frontend?code=${code}&flow_id=${flowId}`;

  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-Verigram-Api-Version': '1.1', // TODO get from preset
      'X-Verigram-Access-Token': credentials.access_token,
      'X-Verigram-Person-Id': credentials.person_id,
    },
  };

  const client = axios.create();

  axiosRetry(client, { retries: 3 });

  return client.post(url, null, config)
}
