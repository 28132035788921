import { h } from "preact";
import {useTranslation} from "react-i18next";


export default function KaspiAppGuide() {

    const { t } = useTranslation();

    return (
        <div className="vg-content doc-rec-egov-code-kaspi-guide-subpage-content">
            <h3 className="vg-page-title doc-rec-egov-code-kaspi-guide-subpage-title">
                {t("KaspiAppGuideTitle")}
            </h3>

            <div className="vg-text doc-rec-egov-code-kaspi-guide-subpage-text">
                {t("KaspiAppGuideText")}
            </div>

            <ol className="doc-rec-egov-code-kaspi-guide-subpage-ol">
                <li>
                    {t("KaspiAppGuideStep1")}
                </li>
                <li>
                    {t("KaspiAppGuideStep2")}
                </li>
                <li>
                    {t("KaspiAppGuideStep3")}
                </li>
                <li>
                    {t("KaspiAppGuideStep4")}
                </li>
                <li>
                    {t("KaspiAppGuideStep5")}
                </li>
                <li>
                    {t("KaspiAppGuideStep6")}
                </li>
            </ol>

        </div>
    );
}
