import { h, } from "preact";
import { useEffect, useState } from "preact/hooks";

import {useTranslation} from "react-i18next";
import {executeBackground} from "../../hooks/request";
import LoadingWheel from "../../components/LoadWheel";
import StepIndicator from "../../components/StepIndicator";
import {countTotalSteps, getWhichStep} from "../../hooks/utils";


export default function VerifacePage(props) {

    const { t } = useTranslation();

    useEffect(async () => {
        const preset = props.preset;

        await executeBackground(props.flowId, 'veriface', preset);

    }, [])

    const logoUrl = props?.preset?.template?.ui?.logo_url;

    const currentStep = getWhichStep('veriface', props.preset);
    const totalSteps = countTotalSteps(props.preset)

    return (
            <div className="veriface-page-root-div">

                {logoUrl && <img className="vg-consumer-logo veriface-page-consumer-logo"
                                 src={logoUrl} alt="Logo Image"
                />}

                <h3 className="vg-page-title veriface-page-title">
                    {t("VerifacePageTitle")}
                </h3>

                <div className="vg-loading-wheel veriface-page-loading-wheel">
                    <LoadingWheel/>
                    <div
                        className="veriface-page-loading-wheel-text"
                    >
                        {t("VerifacePageText")}
                    </div>
                </div>

                <div
                    className="veriface-page-step-indicator"
                >
                    <StepIndicator
                        currentStep={currentStep}
                        totalSteps={totalSteps}
                    />
                </div>

            </div>
    );
}
