import { h } from "preact";
import { useEffect, useState } from "preact/hooks";

import { useTranslation } from "react-i18next";
import Verilive from "./Verilive";
import { VeriliveSubpage } from "./enums";
import VeriliveGuide from "./VeriliveGuide";
import Button from "antd/es/button";

export default function VerilivePage(props) {
  const { t } = useTranslation();

  const [subpage, setSubpage] = useState(VeriliveSubpage.VERILIVE_GUIDE);

  const logoUrl = props?.preset?.template?.ui?.logo_url;

  const onGuideNextButtonClick = async (event) => {
    setSubpage(VeriliveSubpage.VERILIVE);
  };

  let content;
  switch (subpage) {
    case VeriliveSubpage.VERILIVE_GUIDE:
      content = (
        <div className="verilive-guide-subpage-root-div">
          {logoUrl && (
            <img
              className="vg-consumer-logo verilive-page-consumer-logo verilive-guide-subpage-consumer-logo"
              src={logoUrl}
              alt="Logo Image"
            />
          )}

          <VeriliveGuide />

          <Button
            className="vg-button vg-next-button verilive-guide-subpage-next-button"
            onClick={onGuideNextButtonClick}
          >
            {t("NextButton")}
          </Button>
        </div>
      );
      break;

    case VeriliveSubpage.VERILIVE:
      content = <Verilive preset={props.preset} flowId={props.flowId} />;
      break;

    default:
      content = (
        <div>
          <p>Error</p>
        </div>
      );
  }

  return <div className="verilive-page-root-div">{content}</div>;
}
