import { h } from "preact";
import { useState, useEffect } from "preact/hooks";
import SixDigitCodeInput from "./SixDigitCodeCodeInput";
import { useTranslation } from "react-i18next";

export default function CodeInput({ onCode }) {
  const { t } = useTranslation();

  const [timeLeft, setTimeLeft] = useState(60);

  useEffect(() => {

    const updateTimer = () => {
      if (timeLeft > 0) {
        setTimeLeft((prevTime) => prevTime - 1);
      } else {
        clearInterval(timerInterval);
      }
    };

    const timerInterval = setInterval(updateTimer, 1000);

    return () => clearInterval(timerInterval);
  }, [timeLeft]);

  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;

  const formattedTime = `${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;

  return (
    <div className="vg-content doc-rec-egov-code-input-subpage-content">
      <h3 className="vg-page-title doc-rec-egov-code-input-subpage-title">
        {t("EgovCodePageTitle")}
      </h3>

      <div className="vg-text doc-rec-egov-code-input-subpage-text">
        {t("EgovCodePageSixDigitText")}
      </div>

      <div className="doc-rec-egov-code-input-subpage-six-digit-code-input">
        <SixDigitCodeInput onCode={onCode} />
      </div>

      <div className="doc-rec-egov-code-input-subpage-timer">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ marginLeft: "15px" }}
        >
          <path
            d="M9.99996 18.3327C5.41663 18.3327 1.66663 14.5827 1.66663 9.99935C1.66663 5.41602 5.41663 1.66602 9.99996 1.66602C14.5833 1.66602 18.3333 5.41602 18.3333 9.99935C18.3333 14.5827 14.5833 18.3327 9.99996 18.3327ZM9.99996 3.33268C6.33329 3.33268 3.33329 6.33268 3.33329 9.99935C3.33329 13.666 6.33329 16.666 9.99996 16.666C13.6666 16.666 16.6666 13.666 16.6666 9.99935C16.6666 6.33268 13.6666 3.33268 9.99996 3.33268ZM13.5833 12.9993C13.8333 12.666 13.75 12.0827 13.4166 11.8327C12.75 11.3327 10.8333 9.99935 10.8333 9.99935C10.8333 9.99935 10.8333 7.16602 10.8333 6.24935C10.8333 5.74935 10.5 5.41602 9.99996 5.41602C9.49996 5.41602 9.16663 5.74935 9.16663 6.24935C9.16663 7.24935 9.16663 9.41602 9.16663 10.416C9.16663 10.666 9.33329 10.916 9.49996 11.0827C10.25 11.5827 11.6666 12.666 12.4166 13.166C12.8333 13.416 13.3333 13.3327 13.5833 12.9993Z"
            fill="#7B756B"
          />
        </svg>

        <div className="doc-rec-egov-code-input-subpage-timer-text">
          {t("EgovCodePageCountdownText", { countdown: formattedTime })}
        </div>
      </div>
    </div>
  );
}
