import { h } from "preact";
import {useTranslation} from "react-i18next";


export default function HalykAppGuide() {

    const { t } = useTranslation();

    return (
        <div className="vg-content doc-rec-egov-code-halyk-guide-subpage-content">
            <h3 className="vg-page-title doc-rec-egov-code-halyk-guide-subpage-title">
                {t("HalykAppGuideTitle")}
            </h3>

            <div className="vg-text doc-rec-egov-code-halyk-guide-subpage-text">
                {t("HalykAppGuideText")}
            </div>

            <ol className="doc-rec-egov-code-halyk-guide-subpage-ol">
                <li>
                    {t("HalykAppGuideStep1")}
                </li>
                <li>
                    {t("HalykAppGuideStep2")}
                </li>
                <li>
                    {t("HalykAppGuideStep3")}
                </li>
                <li>
                    {t("HalykAppGuideStep4")}
                </li>
                <li>
                    {t("HalykAppGuideStep5")}
                </li>
            </ol>

        </div>
    );
}
