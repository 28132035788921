import { h } from "preact";
import { useTranslation } from "react-i18next";

export default function FinishPage(props) {
  const { t } = useTranslation();

  const logoUrl = props?.preset?.template?.ui?.logo_url;

  return (
    <div className="finish-page-root-div">
      {logoUrl && (
        <img
          className="vg-consumer-logo finish-page-consumer-logo"
          src={logoUrl}
          alt="Logo Image"
        />
      )}

      <h3 className="vg-page-title consent-page-title">
        {t("FinishPageTitle")}
      </h3>

      <div className="vg-text finish-page-text">
        {t("FinishPageText")}
      </div>
    </div>
  );
}
