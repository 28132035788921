import { h, } from "preact";
import { useEffect, useState } from "preact/hooks";
import {executeBackground} from "../../hooks/request";
import {countTotalSteps, getWhichStep} from "../../hooks/utils";
import LoadingWheel from "../../components/LoadWheel";
import StepIndicator from "../../components/StepIndicator";
import {useTranslation} from "react-i18next";


export default function AmlCftPage(props) {

    const { t } = useTranslation();

    useEffect(async () => {
        // const preset = (await requestPreset()).data;
        const preset = props.preset;

        await executeBackground(props.flowId, 'aml-cft', preset);

    }, [])

    const logoUrl = props?.preset?.template?.ui?.logo_url;

    const currentStep = getWhichStep('aml-cft', props.preset);
    const totalSteps = countTotalSteps(props.preset)

    return (
        <div className="aml-cft-page-root-div">

            {logoUrl && <img className="vg-consumer-logo aml-cft-page-consumer-logo"
                             src={logoUrl} alt="Logo Image"
            />}

            <h3 className="vg-page-title aml-cft-page-title">
                {t("AmlCftTitle")}
            </h3>

            <div className="vg-loading-wheel aml-cft-page-loading-wheel">
                <LoadingWheel/>

                <div
                    className="aml-cft-page-loading-wheel-text"
                >
                    {t("AmlCftLoadingWheelText")}
                </div>
            </div>

            <div
                className="aml-cft-page-step-indicator"
            >
                <StepIndicator
                    currentStep={currentStep}
                    totalSteps={totalSteps}
                />
            </div>

        </div>
    );
}
