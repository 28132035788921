

export function getStep(target, preset) {
  const pipeline = preset.template.pipeline;
  return pipeline.find((step) => step.service_name === target);
}


export function getWhichStep(target, preset) {
  const pipeline = preset.template.pipeline;
  return pipeline.findIndex((step) => step.service_name === target);
}


export function countTotalSteps(preset){
  const pipeline = preset.template.pipeline;
  return pipeline.length;
}


export async function sendMessageToParentPage(type, data) {
  const message = {
    "source": "verigram",
    "type": type,
    "data": data,
  }
  window.parent.postMessage(message, "*");
}

export function parseFlowId() {
    const path = window.location.pathname;
    const split = path.split("/")
    const flowId = split[split.indexOf("hf2") + 1];
    return flowId;
}


// fetch('https://example.com/path/to/external-styles.css')
//       .then(response => response.text())
//       .then(cssText => {
//         // Create a style element
//         const styleElement = document.createElement('style');
//         styleElement.type = 'text/css';
//         styleElement.appendChild(document.createTextNode(cssText));
//
//         // Append the style element to the document head
//         document.head.appendChild(styleElement);
//       })
//       .catch(error => console.error('Error fetching CSS:', error));
