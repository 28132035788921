import { h } from "preact";
import { useState } from "preact/hooks";

export default function SixDigitCodeInput({ onCode }) {
  const [code, setCode] = useState(["", "", "", "", "", ""]);

  const handleChange = (index, value) => {
    const newCode = [...code];
    newCode[index] = value;

    // Move focus to the next cell if a digit is entered
    if (value !== "" && index < code.length - 1) {
      document.getElementById(`code-input-${index + 1}`).focus();
    }

    setCode(newCode);

    if (newCode.join("").length === 6) {
      onCode(newCode.join(""));
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && index > 0) {
      document.getElementById(`code-input-${index - 1}`).focus();
    }

    if (e.key === "Backspace" && index === 5) {
      const newCode = [...code];
      newCode[index] = "";
      setCode(newCode);
    }
  };

  return (
    <div>
      {code.map((value, index) => (
        <input
          key={index}
          id={`code-input-${index}`}
          type="text"
          maxLength="1"
          value={value}
          pattern="\d*"
          onChange={(e) => handleChange(index, e.target.value)}
          onKeyDown={(e) => handleKeyDown(index, e)}
        />
      ))}
    </div>
  );
}
