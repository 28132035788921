import { h } from "preact";
import React, { useState } from 'react';
import {CodeAppChoiceEnum} from "../enums";
import {useTranslation} from "react-i18next";

export default function CodeAppChoiceRadioBox({onOptionChange}) {

    const { t } = useTranslation();

    const onClick = (value) => {
        onOptionChange(value);
    };

    return (
        <div className="doc-rec-egov-code-app-choice-subpage-icon-container">
            <img
                className="doc-rec-egov-code-app-choice-subpage-halyk-icon"
                src="https://s3.eu-central-1.amazonaws.com/veridoc-statics.verigram.ai/egov-app-logos/halyk.png"
                alt="Halyk"
                onClick={() => onClick(CodeAppChoiceEnum.HALYK)}
            />

            <img
                className="doc-rec-egov-code-app-choice-subpage-kaspi-icon"
                src="https://s3.eu-central-1.amazonaws.com/veridoc-statics.verigram.ai/egov-app-logos/kaspi.png"
                alt="Kaspi"
                onClick={() => onClick(CodeAppChoiceEnum.KASPI)}
            />

            <img
                className="doc-rec-egov-code-app-choice-subpage-egov-icon"
                src="https://s3.eu-central-1.amazonaws.com/veridoc-statics.verigram.ai/egov-app-logos/egov.png"
                alt="Egov"
                onClick={() => onClick(CodeAppChoiceEnum.EGOV)}
            />
        </div>
    );
};
