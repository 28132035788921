import React from "@preact/compat";
import { render, h } from "preact";

// import "antd/dist/antd.variable.min.css";
import "./assets/styles/style.css";

import App from "./App";
import {requestPreset} from "./hooks/request";

import {parseFlowId} from "./hooks/utils";
import {initI18next} from "./i18next";


const flowId = parseFlowId();

requestPreset(flowId)
  .then((response) => {
    const preset = response.data;

    initI18next(preset).then(() => {
      render(<App preset={preset} />, document.body);
    })

  })
  .catch((error) => {
    // TODO send iframe event
    // TODO navigaet back
  })


