import { h } from "preact";
import {useTranslation} from "react-i18next";


export default function VeriliveGuide() {

    const { t } = useTranslation();

    return (
        <div className="vg-content verilive-guide-subpage-content">
            <h3 className="vg-page-title verilive-guide-subpage-title">
                {t("VeriliveGuideTitle")}
            </h3>

            <div className="vg-text verilive-guide-subpage-text">
                {t("VeriliveGuideText")}
            </div>

        </div>
    );
}
