import { h, Fragment } from "preact";
import { useEffect, useState } from "preact/hooks";
import { executeBackground } from "../../hooks/request";
import QRCode from "qrcode";
import QRState from "./states/QRState";
import ReturnState from "./states/ReturnState";
import LoadWheel from "../../components/LoadWheel";

export default function DeviceFilterPage(props) {
  const [qrCodeImgSrc, setQrCodeImgSrc] = useState("");
  const [loading, setLoading] = useState(true);
  const [deviceState, setDeviceState] = useState("");
  const [keepOpen, setKeepOpen] = useState(false);

  useEffect(async () => {
    const preset = props.preset;

    const response = await executeBackground(
      props.flowId,
      "device-filter",
      preset
    );

    setDeviceState(response.data.state);

    const { qr_url } = response.data;

    const qrCodeDataUrl = await QRCode.toDataURL(qr_url);

    setQrCodeImgSrc(qrCodeDataUrl);
    
    setKeepOpen(response.data.keep_page_open || false);

    setLoading(false);
  }, []);

  const logoUrl = props?.preset?.template?.ui?.logo_url;

  const renderState = () => {
    switch (deviceState) {
      case "qr":
        return (
          <QRState
            logoUrl={logoUrl}
            loading={loading}
            qrCodeImgSrc={qrCodeImgSrc}
            keepOpen={keepOpen}
          />
        );

      case "finish":
        return <ReturnState logoUrl={logoUrl} />;

      default:
        return (
          <div
           className="device-filter-page-loading-content"
          >
            <LoadWheel />
          </div>
        );
    }
  };

  return <>{renderState()}</>;
}
