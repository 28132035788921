import { h } from "preact";
import { useEffect, useState } from "preact/hooks";

import { useTranslation } from "react-i18next";
import CheckboxWithText from "../../components/CheckboxWithText";
import { getStep } from "../../hooks/utils";
import Button from "antd/es/button";

import { makeConsentRequest } from "../../hooks/request";

export default function ConsentPage(props) {
  const { t } = useTranslation();

  const logoUrl = props?.preset?.template?.ui?.logo_url;
  const consentStep = getStep("consent", props.preset);

  var consentAgreementsNumber = consentStep?.config?.agreements;
  if (!consentAgreementsNumber) {
    consentAgreementsNumber = 1;
  }
  const consentAgreements = Array.from(
    Array(consentAgreementsNumber).keys()
  ).map((index) => ({
    label: t(`ConsentAgreement${index + 1}`),
  }));

  const [ongoingRequest, setOngoingRequest] = useState(false);

  const [checkboxStates, setCheckboxStates] = useState(
    consentAgreements.map(() => false)
  );

  const [allButtonsAreChecked, setAllButtonsAreChecked] = useState(false);

  // TODO: consider safer way to check if all checkboxes are checked
  useEffect(() => {
    setAllButtonsAreChecked(checkboxStates.every((state) => state));
  }, [checkboxStates]);

  const handleCheckboxChange = (index, newState) => {
    const newCheckboxStates = [...checkboxStates];
    newCheckboxStates[index] = newState;
    setCheckboxStates(newCheckboxStates);
  };

  const onNextButtonClick = () => {
    setOngoingRequest(true);

    makeConsentRequest(consentAgreements, props.preset, props.flowId)
      .then((response) => {
        setTimeout(() => {
          setOngoingRequest(false);
        }, 10000);
      })
      .catch((error) => {
        setTimeout(() => {
          setOngoingRequest(false);
        }, 3000);
      });
  };

  return (
    <div className="consent-page-root-div">
      {logoUrl && (
        <img
          className="vg-consumer-logo consent-page-consumer-logo"
          src={logoUrl}
          alt="Logo Image"
        />
      )}

      <h3 className="vg-page-title consent-page-title">
        {t("ConsentPageTitle")}
      </h3>

      <div className="consent-page-agreements-group">
        {consentAgreements.map((agreement, index) => (
          <div className="consent-page-agreement" key={index}>
            <CheckboxWithText
              label={agreement.label}
              onChange={(newState) => handleCheckboxChange(index, newState)}
            />
          </div>
        ))}
      </div>

      <Button
        className="vg-button vg-next-button consent-page-next-button"
        onClick={onNextButtonClick}
        disabled={!allButtonsAreChecked || ongoingRequest}
      >
        {t("NextButton")}
      </Button>
    </div>
  );
}
