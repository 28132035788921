import { h } from "preact";

export default function StepIndicator({ totalSteps, currentStep }) {
  const gapWidthPercent = 1;
  const gapsNumber = totalSteps - 1;
  const widthPercent = (100 - gapWidthPercent * gapsNumber) / totalSteps;

  const rectangles = [];

  for (let i = 0; i < totalSteps - 2; i++) {
    const isBright = i < currentStep;
    rectangles.push(
      <div
        key={i}
        className={`vg-step-indicator-rectangle ${
          isBright ? "vg-step-indicator-bright" : ""
        }`}
        style={{ width: `${widthPercent}%`, marginLeft: `${gapWidthPercent}%` }}
      />
    );
  }

  return (
    <div className="vg-step-indicator">
      {totalSteps > 0 && (
        <div
          className={`vg-step-indicator-half-circle ${
            currentStep >= 0 ? "vg-step-indicator-bright" : ""
          }`}
          style={{
            width: `${widthPercent}%`,
            marginLeft: `${gapWidthPercent}%`,
          }}
        />
      )}

      {rectangles}

      {totalSteps >= 2 && (
        <div
          className={`vg-step-indicator-half-circle ${
            currentStep >= totalSteps - 1 ? "vg-step-indicator-bright" : ""
          }`}
          style={{
            width: `${widthPercent}%`,
            marginLeft: `${gapWidthPercent}%`,
          }}
        />
      )}
    </div>
  );
}
