import { useTranslation } from "react-i18next";

export function getVeridocTargetUrl() {
    return process.env.VD_URL;
}

export function getVeridocOptions(pipeline) {
    let options = [];
    pipeline?.forEach((obj) => {
        if (obj.service_name === "veridoc") {
            if (obj.options)
                options = obj.options;
        }
    });
    return options;
}

export function getCountrySelectorOptions(pipeline) {
    let options;
    pipeline?.forEach((obj) => {
        if (obj.service_name === "veridoc") {
            options = {
                "country_selector_enabled": obj.country_selector_enabled ?? true,
                "country_selector_visible": obj.country_selector_visible ?? true,
                "country_code_default": obj.country_code_default ?? "KAZ"
            }
        }
    });

    return options;
}

export function getShowSelectorPageFlag(pipeline) {
    let flag = true;
    pipeline?.forEach((obj) => {
        if (obj.service_name === "veridoc") {
            flag = obj.show_selector_page ?? true;
        }
    });

    return flag;
}

export function getDeliveredVeridocConfig(pipeline) {
    let config = {};
    pipeline?.forEach((obj) => {
        if (obj.service_name === "veridoc") {
            if (obj.config) {
                config = obj.config;
            } else if (obj.doc_type_default) {
                config.docType = obj.doc_type_default === 33 ? 1 : obj.doc_type_default;
            }
        }
    });

    return config;
}

export function mergeConfigs(base, updated) {
    for (const [key, value] of Object.entries(updated)) {
        if (key === "recognition_mode") {
            base['recognitionMode'] = value;
        }
        base[key] = value;
    }

    return base;
}

export function getVeridocConfigParams() {

    const { t } = useTranslation();

    const rendererConfigParams = {
        "isHosted": true,
        "fillMode": true,
        "placeholder": true,
        "startButton": true,
        "containerBorderThickness": 1,
        "containerBorderRadius": 3,
        "containerBorderColor": {
            "default": "#ffffff"
        },
        "frame": true,
        "frameBorderThickness": 3,
        "frameBorderRadius": 20,
        "frameColor": {
            "default": "rgba(255, 255, 255, 1.0)",
            "detected": "rgba(30, 255, 88, 1.0)"
        },
        "overlay": true,
        "overlayPermanent": true,
        "overlayColor": {
            "default": "#0e0e38"
        },
        "upperBarColor": {
            "default": "rgba(255, 255, 255, 1.0)"
        },
        "lowerBarColor": {
            "default": "#a2d2ff",
            "error": "#ffccd5"
        },
        "buttonColor": {
            "default": "#305076"
        },
        "buttonTextColor": {
            "default": "#ffffff"
        },
        "overlayTransparency": {
            "default": 0.7
        },
        "icons": true,
        "hint": true,
        "hintTextColor": {
            "default": "#ffffff"
        },
        "mirrorPreview": false
    };

    // const hintsConfigParams = {
    //     "AwaitingToStart": t("AwaitingToStart"),
    //     "BlurryError": t("BlurryError"),
    //     "BrowserNotSupportedError": t("BrowserNotSupportedError"),
    //     "CameraCheck": t("CameraCheck"),
    //     "CameraPermissionRequest": t("CameraPermissionRequest"),
    //     "DocumentDetected": t("DocumentDetected"),
    //     "DocumentNotDetected": t("DocumentNotDetected"),
    //     "DocumentNotFoundError": t("DocumentNotFoundError"),
    //     "DocumentScanning": t("DocumentScanning"),
    //     "Error": t("Error"),
    //     "FaceNotFoundError": t("FaceNotFoundError"),
    //     "FieldsRecognitionError": t("FieldsRecognitionError"),
    //     "GlareError": t("GlareError"),
    //     "IdHasNoIinError": t("IdHasNoIinError"),
    //     "NotAllowedError": t("NotAllowedError"),
    //     "NotFoundError": t("NotFoundError"),
    //     "NotReadableError": t("NotReadableError"),
    //     "NotSupportedError": t("NotSupportedError"),
    //     "OverconstrainedError": t("OverconstrainedError"),
    //     "PhotocopyError": t("PhotocopyError"),
    //     "PressToStart": t("PressToStart"),
    //     "RecognitionError": t("RecognitionError"),
    //     "RecognitionStarted": t("RecognitionStarted"),
    //     "RecognitionStopped": t("RecognitionStopped"),
    //     "RecognitionSucceeded": t("RecognitionSucceeded"),
    //     "Retake": t("Retake"),
    //     "SecurityError": t("SecurityError"),
    //     "ServerAuthorizationError": t("ServerAuthorizationError"),
    //     "ServerError": t("ServerError"),
    //     "ShowBackDocument": t("ShowBackDocument"),
    //     "ShowBackError": t("ShowBackError"),
    //     "ShowBackId": t("ShowBackId"),
    //     "ShowDocument": t("ShowDocument"),
    //     "ShowFrontDocument": t("ShowFrontDocument"),
    //     "ShowFrontError": t("ShowFrontError"),
    //     "ShowFrontId": t("ShowFrontId"),
    //     "ShowId": t("ShowId"),
    //     "ShowMrz": t("ShowMrz"),
    //     "ShowPassport": t("ShowPassport"),
    //     "ShowSimcard": t("ShowSimcustomcard"),
    //     "SlowInternetError": t("SlowInternetError"),
    //     "StartScan": t("StartScan"),
    //     "TwoSidesMismatchError": t("TwoSidesMismatchError"),
    //     "vdoc_back_id": t("VeridocBackId"),
    //     "vdoc_front_id": t("VeridocFrontId"),
    //     "vdoc_keep_showing_document": t("VeridocKeepShowingDocument"),
    //     "vdoc_searching_document": t("VeridocSearchingDocument"),
    // };

    const recognitionConfigParams = {
        "autoDocType": false,
        "imageOnlyMode": false,
        "glareCheck": false,
        "photocopyCheck": false,
        "translitCheck": false,
        "docType": 1,
        "lang": t("VeridocLang"),
        "recognitionMode": 1
    };

    // if (presets && presets.lang && ["en", "ru", "kz"].includes(presets.lang)) {
    //     recognitionConfigParams.lang = presets.lang;
    // }
    //
    // if (presets && presets.documentType) {
    //     recognitionConfigParams.docType = presets.documentType;
    // }
    return {
        ...recognitionConfigParams,
        // hints: hintsConfigParams,
        render: rendererConfigParams
    };
}
