import { h } from "preact";
import { getDeliveredVeridocConfig, getVeridocConfigParams, getVeridocTargetUrl, mergeConfigs } from "./VeridocUtils";
import { useEffect } from "preact/hooks";
import { getStep } from "../../hooks/utils";

export default function Scanner({
  preset,
  onSuccessCallback,
  onErrorCallback,
  onUpdateCallback,
  flowId,
  docType,
}) {
  const [targetUrl, configParams] = [
    getVeridocTargetUrl(),
    getVeridocConfigParams(),
  ];

  let accessToken = "";
  let personId = "";

  useEffect(() => {
    const initializeVeridoc = async () => {
      accessToken = preset.access_token;
      personId = preset.person_id;

      globalThis.veridoc?.setFlowId(flowId ?? "");

      globalThis.veridoc?.setAccessToken(accessToken, personId);

      let locale = preset.locale;
      if (locale === "kk") {
        locale = "kz";
      }
      configParams.docType = docType;
      configParams.lang = locale;

      const step = getStep("veridoc", preset);

      configParams.autopassSingleOption = true;
      configParams.render.fillMode = true;

      const updatedConfigParams = mergeConfigs(configParams, getDeliveredVeridocConfig(preset?.template?.pipeline));

      globalThis.veridoc.successCallback = onSuccessCallback;
      globalThis.veridoc.failCallback = onErrorCallback;
      globalThis.veridoc.errorCallback = onErrorCallback;
      globalThis.veridoc.updateCallback = onUpdateCallback;

      for (let i = 0; i < step.max_retries + 1; i++) {
        try {
          globalThis.verilive?.dispose();
          await globalThis.veridoc?.init(targetUrl, "", updatedConfigParams);
          // globalThis.veridoc?.start();
          break;
        } catch (e) {
          console.log(e);
        }
      }
    };

    initializeVeridoc();

    return () => globalThis.veridoc?.dispose();
  }, []);

  return (
    <div
      className="vg-content doc-rec-scan-subpage-content"
      style={{ height: "100%" }}
    >
      <div id="id_veridoc"></div>
    </div>
  );
}
