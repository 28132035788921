import { h } from "preact";

const LoadingWheel = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      style="margin: auto; display: block; shape-rendering: auto; animation-play-state: running; animation-delay: 0s;"
      width="52"
      height="52"
      viewBox="38 40 24 20"
      preserveAspectRatio="xMidYMid"
      className="vg-spinner"
    >
      <g
        transform="rotate(0 50 50)"
        style="animation-play-state: running; animation-delay: 0s;"
      >
        <rect
          x="48.5"
          y="38.5"
          rx="0"
          ry="0"
          width="3"
          height="3"
          fill="#df1317"
          style="animation-play-state: running; animation-delay: 0s;"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.7543859649122806s"
            begin="-1.6374269005847952s"
            repeatCount="indefinite"
            style="animation-play-state: running; animation-delay: 0s;"
          ></animate>
        </rect>
      </g>
      <g
        transform="rotate(24 50 50)"
        style="animation-play-state: running; animation-delay: 0s;"
      >
        <rect
          x="48.5"
          y="38.5"
          rx="0"
          ry="0"
          width="3"
          height="3"
          fill="#df1317"
          style="animation-play-state: running; animation-delay: 0s;"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.7543859649122806s"
            begin="-1.5204678362573099s"
            repeatCount="indefinite"
            style="animation-play-state: running; animation-delay: 0s;"
          ></animate>
        </rect>
      </g>
      <g
        transform="rotate(48 50 50)"
        style="animation-play-state: running; animation-delay: 0s;"
      >
        <rect
          x="48.5"
          y="38.5"
          rx="0"
          ry="0"
          width="3"
          height="3"
          fill="#df1317"
          style="animation-play-state: running; animation-delay: 0s;"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.7543859649122806s"
            begin="-1.4035087719298245s"
            repeatCount="indefinite"
            style="animation-play-state: running; animation-delay: 0s;"
          ></animate>
        </rect>
      </g>
      <g
        transform="rotate(72 50 50)"
        style="animation-play-state: running; animation-delay: 0s;"
      >
        <rect
          x="48.5"
          y="38.5"
          rx="0"
          ry="0"
          width="3"
          height="3"
          fill="#df1317"
          style="animation-play-state: running; animation-delay: 0s;"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.7543859649122806s"
            begin="-1.286549707602339s"
            repeatCount="indefinite"
            style="animation-play-state: running; animation-delay: 0s;"
          ></animate>
        </rect>
      </g>
      <g
        transform="rotate(96 50 50)"
        style="animation-play-state: running; animation-delay: 0s;"
      >
        <rect
          x="48.5"
          y="38.5"
          rx="0"
          ry="0"
          width="3"
          height="3"
          fill="#df1317"
          style="animation-play-state: running; animation-delay: 0s;"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.7543859649122806s"
            begin="-1.1695906432748537s"
            repeatCount="indefinite"
            style="animation-play-state: running; animation-delay: 0s;"
          ></animate>
        </rect>
      </g>
      <g
        transform="rotate(120 50 50)"
        style="animation-play-state: running; animation-delay: 0s;"
      >
        <rect
          x="48.5"
          y="38.5"
          rx="0"
          ry="0"
          width="3"
          height="3"
          fill="#df1317"
          style="animation-play-state: running; animation-delay: 0s;"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.7543859649122806s"
            begin="-1.0526315789473684s"
            repeatCount="indefinite"
            style="animation-play-state: running; animation-delay: 0s;"
          ></animate>
        </rect>
      </g>
      <g
        transform="rotate(144 50 50)"
        style="animation-play-state: running; animation-delay: 0s;"
      >
        <rect
          x="48.5"
          y="38.5"
          rx="0"
          ry="0"
          width="3"
          height="3"
          fill="#df1317"
          style="animation-play-state: running; animation-delay: 0s;"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.7543859649122806s"
            begin="-0.935672514619883s"
            repeatCount="indefinite"
            style="animation-play-state: running; animation-delay: 0s;"
          ></animate>
        </rect>
      </g>
      <g
        transform="rotate(168 50 50)"
        style="animation-play-state: running; animation-delay: 0s;"
      >
        <rect
          x="48.5"
          y="38.5"
          rx="0"
          ry="0"
          width="3"
          height="3"
          fill="#df1317"
          style="animation-play-state: running; animation-delay: 0s;"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.7543859649122806s"
            begin="-0.8187134502923976s"
            repeatCount="indefinite"
            style="animation-play-state: running; animation-delay: 0s;"
          ></animate>
        </rect>
      </g>
      <g
        transform="rotate(192 50 50)"
        style="animation-play-state: running; animation-delay: 0s;"
      >
        <rect
          x="48.5"
          y="38.5"
          rx="0"
          ry="0"
          width="3"
          height="3"
          fill="#df1317"
          style="animation-play-state: running; animation-delay: 0s;"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.7543859649122806s"
            begin="-0.7017543859649122s"
            repeatCount="indefinite"
            style="animation-play-state: running; animation-delay: 0s;"
          ></animate>
        </rect>
      </g>
      <g
        transform="rotate(216 50 50)"
        style="animation-play-state: running; animation-delay: 0s;"
      >
        <rect
          x="48.5"
          y="38.5"
          rx="0"
          ry="0"
          width="3"
          height="3"
          fill="#df1317"
          style="animation-play-state: running; animation-delay: 0s;"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.7543859649122806s"
            begin="-0.5847953216374269s"
            repeatCount="indefinite"
            style="animation-play-state: running; animation-delay: 0s;"
          ></animate>
        </rect>
      </g>
      <g
        transform="rotate(240 50 50)"
        style="animation-play-state: running; animation-delay: 0s;"
      >
        <rect
          x="48.5"
          y="38.5"
          rx="0"
          ry="0"
          width="3"
          height="3"
          fill="#df1317"
          style="animation-play-state: running; animation-delay: 0s;"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.7543859649122806s"
            begin="-0.4678362573099415s"
            repeatCount="indefinite"
            style="animation-play-state: running; animation-delay: 0s;"
          ></animate>
        </rect>
      </g>
      <g
        transform="rotate(264 50 50)"
        style="animation-play-state: running; animation-delay: 0s;"
      >
        <rect
          x="48.5"
          y="38.5"
          rx="0"
          ry="0"
          width="3"
          height="3"
          fill="#df1317"
          style="animation-play-state: running; animation-delay: 0s;"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.7543859649122806s"
            begin="-0.3508771929824561s"
            repeatCount="indefinite"
            style="animation-play-state: running; animation-delay: 0s;"
          ></animate>
        </rect>
      </g>
      <g
        transform="rotate(288 50 50)"
        style="animation-play-state: running; animation-delay: 0s;"
      >
        <rect
          x="48.5"
          y="38.5"
          rx="0"
          ry="0"
          width="3"
          height="3"
          fill="#df1317"
          style="animation-play-state: running; animation-delay: 0s;"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.7543859649122806s"
            begin="-0.23391812865497075s"
            repeatCount="indefinite"
            style="animation-play-state: running; animation-delay: 0s;"
          ></animate>
        </rect>
      </g>
      <g
        transform="rotate(312 50 50)"
        style="animation-play-state: running; animation-delay: 0s;"
      >
        <rect
          x="48.5"
          y="38.5"
          rx="0"
          ry="0"
          width="3"
          height="3"
          fill="#df1317"
          style="animation-play-state: running; animation-delay: 0s;"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.7543859649122806s"
            begin="-0.11695906432748537s"
            repeatCount="indefinite"
            style="animation-play-state: running; animation-delay: 0s;"
          ></animate>
        </rect>
      </g>
      <g
        transform="rotate(336 50 50)"
        style="animation-play-state: running; animation-delay: 0s;"
      >
        <rect
          x="48.5"
          y="38.5"
          rx="0"
          ry="0"
          width="3"
          height="3"
          fill="#df1317"
          style="animation-play-state: running; animation-delay: 0s;"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.7543859649122806s"
            begin="0s"
            repeatCount="indefinite"
            style="animation-play-state: running; animation-delay: 0s;"
          ></animate>
        </rect>
      </g>
    </svg>
  );
};

export default LoadingWheel;
