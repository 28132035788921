import { h, render, Component } from 'preact';
import { useEffect, useState } from "preact/hooks";
import Router from 'preact-router';

import poll from "./hooks/polling";
import Consent from "./pages/Consent/ConsentPage";
import Verilive from "./pages/Verilive/VerilivePage";
import Veridoc from "./pages/Veridoc/VeridocPage";
import Veriface from "./pages/Veriface/VerifacePage";
import AmlCft from "./pages/AmlCft/AmlCftPage";
import Kisc from "./pages/Kisc/KiscPage";
import KycList from "./pages/KycList/KycListPage";
import FlowForm from "./pages/FlowForm/FlowFormPage";
import Finish from "./pages/Finish/FinishPage";
import DeviceFilter from "./pages/DeviceFilter/DeviceFilterPage"
import Veriphone from "./pages/Veriphone/VeriphonePage"

import './assets/styles/style.css'

import { parseFlowId } from "./hooks/utils";
import { applyCss, fetchCss } from "./hooks/request";


function App({ preset }) {
  useEffect(() => {
    console.log("Preset", preset);
    if (preset.template?.ui?.css) {
      applyCss(preset.template.ui.css);
    }

    let intervalId;

    const performPoll = async () => {
      await poll(preset["callback_url"], parseFlowId(), preset);
    };

    const startPolling = () => {
      // Perform initial poll immediately
      performPoll();

      // Set interval for consecutive polls after the initial one
      intervalId = setInterval(performPoll, 3000);
    };

    const stopPolling = () => {
      // Clear interval to stop polling
      clearInterval(intervalId);
    };

    const handleVisibilityChange = () => {
      if (document.hidden) {
        // Page is not visible, stop polling
        stopPolling();
      } else {
        // Page is visible, start polling again
        startPolling();
      }
    };

    // Add event listener for visibility change
    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Start polling when component mounts
    startPolling();

    // Clean up event listener and interval on component unmount
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      clearInterval(intervalId);
    };
  }, [preset]);


  return (
    <Router>
      <Consent path="/hf2/:flowId/consent" preset={preset} />
      <DeviceFilter path="/hf2/:flowId/device-filter" preset={preset} />
      <Verilive path="/hf2/:flowId/verilive" preset={preset} />
      <Veridoc path="/hf2/:flowId/veridoc" preset={preset} />
      <Veriface path="/hf2/:flowId/veriface" preset={preset} />
      <Kisc path="/hf2/:flowId/kisc" preset={preset} />
      <AmlCft path="/hf2/:flowId/aml-cft" preset={preset} />
      <KycList path="/hf2/:flowId/kyc-list" preset={preset} />
      <Veriphone path="/hf2/:flowId/veriphone" preset={preset} />
      <FlowForm path="/hf2/:flowId/flow-form" preset={preset} />
      <Finish path="/hf2/:flowId/finish" preset={preset} />
    </Router>
  );
}

export default App;
