import { h } from "preact";
import CodeAppChoiceRadioBox from "./CodeAppRadioBox";
import { useTranslation } from "react-i18next";

export default function CodeAppChoice({ onOptionChange }) {
  const { t } = useTranslation();

  const onChange = (value) => {
    onOptionChange(value);
  };

  return (
    <div className="vg-content doc-rec-egov-code-app-choice-subpage-content">
      <h3 className="vg-page-title doc-rec-egov-code-app-choice-subpage-title">
        {t("CodeAppChoiceTitle")}
      </h3>

      <div className="vg-text doc-rec-egov-code-app-choice-subpage-text">
        {t("CodeAppChoiceText")}
      </div>

      <CodeAppChoiceRadioBox onOptionChange={onChange}></CodeAppChoiceRadioBox>
    </div>
  );
}
