import { h } from "preact";
import { useEffect, useState } from "preact/hooks";

import Button from "antd/es/button";
import {
  getVeriliveConfigParams,
  getVerilive1TargetUrl,
} from "./VeriliveUtils";
import { useTranslation } from "react-i18next";
import { getStep } from "../../hooks/utils";

export default function Verilive({ flowId, preset }) {
  const { t } = useTranslation();

  const [verilive1Url, configParams] = [
    getVerilive1TargetUrl(),
    getVeriliveConfigParams(),
  ];

  const [showRetryButton, setShowRetryButton] = useState(false);

  const [errorMessage, setErrorMessage] = useState(null);

  let accessToken = "";
  let personId = "";

  const onRetryButtonClick = async (event) => {
    setShowRetryButton(false);
    const accessToken = preset.access_token;
    const personId = preset.person_id;

    await globalThis.verilive.setVlinkId(flowId);
    globalThis.verilive.start(accessToken, personId);
  };

  const onSuccessCallback = (data) => {
    // TODO: go to transition page
  };

  const onErrorCallback = async (data) => {
    console.error(data);
    setShowRetryButton(true);

    setTimeout(async () => {
      await globalThis.verilive.setVlinkId(flowId);
      globalThis.verilive.start(accessToken, personId);
    }, 5000);
  };

  useEffect(async () => {
    accessToken = preset.access_token;
    personId = preset.person_id;

    globalThis.verilive.successCallback = onSuccessCallback;
    globalThis.verilive.failCallback = onErrorCallback;
    globalThis.verilive.errorCallback = onErrorCallback;

    await globalThis.verilive.setVeriliveOneURL(verilive1Url);
    await globalThis.verilive.setVeriliveTwoURL(window.location.origin);
    await globalThis.verilive.setVlinkId(flowId);

    configParams.lang = t("LivenessConfig");
    const step = getStep("verilive", preset);

    for (let i = 0; i < step.max_retries + 1; i++) {
      try {
        globalThis.veridoc?.dispose();
        await globalThis.verilive.init(verilive1Url, configParams);
        await globalThis.verilive.start(accessToken, personId);
        break;
      } catch (error) {
        console.error(error);
        if (error && error.message) {
          setErrorMessage(error.message);
        }
        // await new Promise(resolve => setTimeout(resolve, 5000));
      }
    }

    return () => {
      globalThis.verilive.dispose();
    };
  }, []);

  return (
    <div className="verilive-subpage-root vg-content verilive-subpage-content">
      <div id="id_verilive" className="verilive-subpage-container" />
      {errorMessage && <h2 style={{ color: "red" }}>{errorMessage}</h2>}
    </div>
  );
}
