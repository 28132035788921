import { h } from "preact";

const CheckboxWithText = ({ label, isChecked, onChange }) => {
  const handleCheckboxChange = (event) => {
    onChange(event.target.checked);
  };

  const parseLabelWithLinks = () => {
    const linkRegex = /\[([^\]]+)\]\(([^)]+)\)/g;
    return label.replace(linkRegex, (match, text, url) => {
      return `<a href="${url}" target="_blank">${text}</a>`;
    });
  };

  return (
    <div>
      <label style={{ display: "flex", alignItems: "start" }}>
        <input
          className="vg-checkbox"
          type="checkbox"
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        <span
          className="consent-page-checkbox-text"
          dangerouslySetInnerHTML={{ __html: parseLabelWithLinks() }}
        />
      </label>
    </div>
  );
};

export default CheckboxWithText;
