import { route } from 'preact-router';
import { sendMessageToParentPage } from "./utils";


export default async function poll(callbackUrl, flowId, preset) {
  const targetUrl = `${window.location.origin}/flow/${flowId}/frontend/next`;

  // add headers to featch
  const headers = new Headers();
  headers.append('X-Verigram-Access-Token', preset["access_token"]);
  headers.append('X-Verigram-Api-Version', '1.1');
  headers.append('X-Verigram-Person-ID', preset["person_id"]);
  const response = await fetch(targetUrl, {
    method: "GET",
    headers: headers,
  });

  if (response.status === 200) {
    const data = await response.json();
    const target = data['target'];

    const allowedTargets = [
      "verilive", "veridoc", "veriface", "kisc",
      "aml-cft", "consent", "flow-form", "kyc-list", "device-filter", "veriphone"
    ];

    sendMessageToParentPage("target", data)

    if (target === "callback") {
      if (callbackUrl === null) {
        route(`/hf2/${flowId}/finish`)
      } else {
        window.location.href = callbackUrl;
      }

    } else if (allowedTargets.includes(target)) {
      route(`/hf2/${flowId}/${target}`);
    }

  }
}
