import { h } from "preact";
import {useTranslation} from "react-i18next";


export default function EgovAppGuide() {

    const { t } = useTranslation();

    return (
        <div className="vg-content doc-rec-egov-code-egov-guide-subpage-content">
            <h3 className="vg-page-title doc-rec-egov-code-egov-guide-subpage-title">
                {t("EgovAppGuideTitle")}
            </h3>

            <div className="vg-text doc-rec-egov-code-egov-guide-subpage-text">
                {t("EgovAppGuideText")}
            </div>

            <ol className="doc-rec-egov-code-egov-guide-subpage-ol">
                <li>
                    {t("EgovAppGuideStep1")}
                </li>
                <li>
                    {t("EgovAppGuideStep2")}
                </li>
                <li>
                    {t("EgovAppGuideStep3")}
                </li>
                <li>
                    {t("EgovAppGuideStep4")}
                </li>
                <li>
                    {t("EgovAppGuideStep5")}
                </li>
            </ol>

        </div>
    );
}
