import { h } from "preact";
import { useEffect, useState } from "preact/hooks";
import { useTranslation } from "react-i18next";
import { getStep } from "../../hooks/utils";
import Button from "antd/es/button";
import Select from "antd/es/select";
import CountrySelector from "../../components/CountrySelector";
import { makeFlowFormRequest } from "../../hooks/request";

const { Option } = Select;

export default function FlowFormPage(props) {
  const { t } = useTranslation();

  const logoUrl = props?.preset?.template?.ui?.logo_url;
  const step = getStep("consent", props.preset);

  const [ongoingRequest, setOngoingRequest] = useState(false);
  const [allInputsAreFilled, setAllInputsAreFilled] = useState(false);

  const [input1, setInput1] = useState("");
  const [input2, setInput2] = useState("");
  const [input3, setInput3] = useState("");
  const [input4, setInput4] = useState("");
  const [input5, setInput5] = useState("");

  const handleInputChange = (inputName, value) => {
    switch (inputName) {
      case "Country":
        setInput1(value);
        break;
      case "City":
        setInput2(value);
        break;
      case "Street":
        setInput3(value);
        break;
      case "Building":
        setInput4(value);
        break;
      case "Apartment":
        setInput5(value);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    // Check if all inputs are filled
    const areAllFilled =
      input1.trim() !== "" &&
      input2.trim() !== "" &&
      input3.trim() !== "" &&
      input4.trim() !== "" &&
      input5.trim() !== "";

    setAllInputsAreFilled(areAllFilled);
  }, [input1, input2, input3, input4, input5]);

  const onNextButtonClick = () => {
    setOngoingRequest(true);
    const data = {
      country_code: input1, // Assuming input1 is the 3-letter country code
      city: input2,
      street: input3,
      building: input4,
      apartment: input5,
    };

    makeFlowFormRequest(data, props.preset, props.flowId)
      .then((response) => {
        setTimeout(() => {
          setOngoingRequest(false);
        }, 10000);
      })
      .catch((error) => {
        setTimeout(() => {
          setOngoingRequest(false);
        }, 3000);
        // TODO: handle error
      });
  };

  return (
    <div className="flow-form-page-root-div">
      {logoUrl && (
        <img
          className="vg-consumer-logo flow-form-page-consumer-logo"
          src={logoUrl}
          alt="Logo Image"
        />
      )}

      <h3 className="vg-page-title flow-form-page-title">
        {t("FlowFormPageTitle")}
      </h3>

      <div className="flow-form-page-inputs-wrapper">
        {[
          { name: "Country", placeholder: t("FlowFormInputCountry") },
          { name: "City", placeholder: t("FlowFormInputCity") },
          { name: "Street", placeholder: t("FlowFormInputStreet") },
          { name: "Building", placeholder: t("FlowFormInputBuilding") },
          { name: "Apartment", placeholder: t("FlowFormInputApartment") },
        ].map((input) => (
          <div
            key={input.name}
            style={{ display: "flex", justifyContent: "center" }}
          >
            {input.name === "Country" ? (
              <CountrySelector
                placeholder={input.placeholder}
                onChange={(value) => handleInputChange(input.name, value)}
                countrySelectorOptions={{
                    country_selector_visible: true,
                    country_selector_enabled: true,
                    country_code_default: "",
                }}
              />
            ) : (
              <input
                type="text"
                onChange={(event) =>
                  handleInputChange(input.name, event.target.value)
                }
                placeholder={input.placeholder}
                className="flow-form-page-input"
              />
            )}
          </div>
        ))}
      </div>

      <Button
        className="vg-button vg-next-button flow-page-page-next-button"
        onClick={onNextButtonClick}
        disabled={!allInputsAreFilled || ongoingRequest}
      >
        {t("NextButton")}
      </Button>
    </div>
  );
}
