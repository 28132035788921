
export const VeridocSubpage = {
  PROCESS_CHOICE: 'process-choice',
  // SCAN_GUIDE: 'scan-guide',
  DOCTYPE_CHOICE: 'doctype-choice',
  SCAN: 'scan',
  SCAN_ERROR: 'scan-error',
  EGOV_CODE_APP_CHOICE: 'egov-code-app-choice',
  EGOV_CODE_HALYK_GUIDE: 'egov-code-halyk-guide',
  EGOV_CODE_KASPI_GUIDE: 'egov-code-kaspi-guide',
  EGOV_CODE_EGOV_GUIDE: 'egov-code-egov-guide',
  EGOV_CODE_INPUT: 'egov-code-input',
  EGOV_CODE_ERROR: 'egov-code-error',
};

export const DocProcessEnum = {
  SCAN: 'scan',
  EGOV_CODE: 'egov-code',
}

export const CodeAppChoiceEnum = {
  HALYK: 'halyk',
  KASPI: 'kaspi',
  EGOV: 'egov',
}

export const DocTypeEnum = {
  PASSPORT: 'passport',
  ID: 'id',
  RESIDENCE: 'residence'
}