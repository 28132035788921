import { h } from "preact";
import { useContext, useEffect } from "preact/hooks";
import { DocProcessEnum } from "./enums";
import { useTranslation } from "react-i18next";
import { Radio } from "antd";

export default function DocumentProcessChoice(props) {
  const { t } = useTranslation();

  return (
    <div className="vg-content doc-rec-process-choice-subpage-content">
      <h3 className="vg-page-title doc-rec-process-choice-subpage-title">
        {t("DocumentProcessChoiceTitle")}
      </h3>

      <div className="vg-text doc-rec-process-choice-subpage-text">
        {t("DocumentProcessChoiceText")}
      </div>

      <h4 className="doc-rec-process-choice-subpage-radio-header">
        {t("DocumentProcessChoiceHeader")}
      </h4>

      <div className="doc-rec-process-choice-subpage-radio-group">
        <Radio.Group
          onChange={(e) => props.onRadioChange(e.target.value)}
          style={{ display: "flex", flexDirection: "column", rowGap: "10px" }}
        >
          <Radio
            value={DocProcessEnum.SCAN}
            id={DocProcessEnum.SCAN}
            name="docChoice"
          >
            <span className="doc-rec-process-choice-subpage-radio-button-text">
              {t("DocumentProcessChoiceScan")}
            </span>
          </Radio>

          <Radio
            value={DocProcessEnum.EGOV_CODE}
            id={DocProcessEnum.EGOV_CODE}
            name="docChoice"
          >
            <span className="doc-rec-process-choice-subpage-radio-button-text">
              {t("DocumentProcessChoiceEgovCode")}
            </span>
          </Radio>
        </Radio.Group>
      </div>
    </div>
  );
}
