export function getVerilive1TargetUrl() {
    return process.env.VL1_URL;
}


export function getVeriliveConfigParams() {
    const config = {
        "recordVideo": false,
        "videoBitrate": 2500000,
        "rotated": false,
        "lang": "en",

        "render": {
            "ui": "2",
            "oval": true,
            "ovalType": "contour",
            "ovalRingColor": {
                "default": "#D6C52A",
                "actionSuccess": "#2AD66F",
                "actionFailure": "#FF3F3F",
                "sessionSuccess": "#2AD66F",
                "sessionFailure": "#FF3F3F"
            },
            "ovalWidth": 1.0,
            "overlay": true,
            "overlayColor": {
                "default": "#0F0C2B"
            },
            "overlayTransparency": {
                "default": 0.45
            },
            "outerOverlayColor": {
                "default": "#0F0C2B"
            },
            "outerOverlayTransparency": {
                "default": 1.0
            },

            "arrow": true,
            "arrowColor": {
                "default": "#F0F0F0"
            },
            "arrowProgressColor": {
                "default": "#404040"
            },

            "hint": true,
            "hintTextColor": {
                "default": "#FFFFFF"
            },
            "hintFontType": "Arial",
            "hintUseProgressiveFontSize": false,
            "hintProgressiveFontSizeMultiplier": 1.0,
            "hintFontSize": 25,
            "hintCloudColor": {
                "default": "#2D312F"
            },

            "videoUploadProgressBar": true,
            "videoUploadProgressBarColor1": "#FFEA82",
            "videoUploadProgressBarColor2": "#eee"
        }
    }

    return config;
}
